import React from 'react';
import styled from 'styled-components';
import { BlueButton } from './shared/';
import { media } from "../utils/breakpoints";

const RowCardWrapper = styled.div`
  margin-top: 70px;

  ${media.lessThan('sm')} {
    margin-top: 36px;
  }
`;

const RowCardSubtitle = styled.h3`
  font-size: 16px;
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  letter-spacing: normal;
  line-height: 23px;
  text-align: center;
  margin-bottom:38px;
`;

const RowCardContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;


const Logo: React.FC<{ logo: any; height?: number }> = ({ logo, height }) => {
  if (typeof logo === `string`) {
    return <img alt="logo" className="logo" height={height || 72} width={72} src={logo} />;
  }


  const Element = logo;
  return <Element className="logo" height={height || 72} width={72} />;
};

type Card = {
  title: string,
  channel: string,
  button: object,
  logo: string,
};

type Props = {
  cards: Card[],
};

const RowCard: React.FC<Props> = props =>{
  const { cards } = props;

  const RowCardRow= styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: ${ 100 / cards.length }%;
      
    .logo {
      display:block;
      margin: 0 auto;
    }
  
    ${media.lessThan('md')} {
      flex-wrap: wrap;
      flex-direction: column;
      flex-basis: ${ 100 / (cards.length / 2) }%;
      margin: 1rem auto;
    }
  
    ${media.lessThan('xs-max')} {
      flex-basis: 100%;
    }
  `;

  const cardItems = cards.map((card:any) => (
    <React.Fragment key={card.title}>
      <RowCardRow>
        <Logo logo={ card.logo } />
        <RowCardSubtitle>
          { card.title }<br />{ card.channel }
        </RowCardSubtitle>
        { card.button && <BlueButton { ...card.button } >{ card.button.text } </BlueButton> }
      </RowCardRow>
    </React.Fragment>
  )); 

  return (
    <div>
      <RowCardWrapper>
        { cards && cards.length > 0 && <RowCardContentWrapper>{ cardItems }</RowCardContentWrapper> }
      </RowCardWrapper>
    </div>
  );
};

export default RowCard;
