import React, {useEffect, useState} from "react";
// @ts-ignore
import EosApi from "eosjs-api";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { media } from "../../utils/breakpoints";
import Jumbotron2 from "../Jumbotron2";

const CustodianBoardWrap = styled.div`
  padding: 16px;
`;

const CustodianAvatar = styled.div<{imageUrl:string}>`
  height: 65px;
  width: 65px;
  background-position: 50%;
  background-size: cover;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.colors.whiteDarkened};
  background-image: url(${props => props.imageUrl});
`;

const CustodianName = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
`;

const CustodianWrap = styled.a`
  display: flex;
  flex-direction: column;
  width: 122px;
  align-items: center;
  margin-bottom: 16px;
  
  ${media.lessThan('sm')} {
    width: 109px;
  }  
`;

const CustodianListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Custodian: React.FC<CustodianType> = props => {
  return (
    <CustodianWrap href={props.link} target='_blank' rel='noopener'>
      <CustodianAvatar imageUrl={props.image}/>
      <CustodianName>{ props.name }</CustodianName>
    </CustodianWrap>
  );
};

type Candidate = {
  is_active: boolean,
  total_votes: number,
  candidate_name: string,
};

type CustodianType = {
  votes: number,
  name: string,
  image: string,
  link: string,
};

const getTableRowsCallConfig = {
  json: true,
  code: "daccustodia1",
  scope: "daccustodia1",
  table: "candidates",
  index_position: 1,
  limit: -1,
};

type Props = {};
const CustodianBoard: React.FC<Props> = props => {
  const { t } = useTranslation();

  const [ custodians, setCustodians ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    const eosjs = new EosApi({ httpEndpoint: 'https://api.eossweden.se'});

    eosjs.getTableRows(getTableRowsCallConfig)
      .then((result:any) => {
        if (result.rows.length > 0) {
          const activeCandidates = result.rows.filter((row:Candidate) => row.is_active);
          const sortedCandidates = activeCandidates.sort((a:Candidate, b:Candidate) => (a.total_votes > b.total_votes) ? -1 : 1);
          return sortedCandidates.slice(0, 21);
        }
      })
      .then((sortedCandidates:any) => {
        const custodianAccountNameList = sortedCandidates.map((custodian:Candidate) => custodian.candidate_name).join(',');

        fetch(`https://api-dac.vigor.ai/v1/eosdac/profile?account=${custodianAccountNameList}`)
          .then((result:any) => result.json())
          .then((resultJson:any) => {
            const custodians = sortedCandidates.map((candidate:Candidate) => {
              const accountData = resultJson.results.filter((accountData:any) => candidate.candidate_name === accountData.account)[0];
              return {
                name: candidate.candidate_name,
                image: accountData.profile.image,
                link: `https://vig.ai/profile/${candidate.candidate_name}`,
                votes: candidate.total_votes,
              }
            });
            setCustodians(custodians);
            setIsLoading(false);
          });
      });
  }, []);
  
  return (
    <CustodianBoardWrap id="custodianBoard">
      <Jumbotron2 title={ t('custodianBoard.title')} content={t('custodianBoard.text')}/>
      { isLoading && <div>Fetching custodians...</div> }
      { !isLoading &&
        <div>
          <CustodianListWrap>
            { custodians.length && custodians.map((custodian:CustodianType) =>
                <Custodian name={custodian.name}
                           votes={custodian.votes}
                           link={custodian.link}
                           image={custodian.image}
                           key={custodian.name}
                />
              )
            }
          </CustodianListWrap>
        </div>
      }
    </CustodianBoardWrap>
  );
};

export default CustodianBoard;
