import React from 'react';
import { useTranslation } from 'react-i18next';
import RowCard from '../RowCard';

import VigorMark from '../../assets/svgs/vig-mark.svg';

const EarnBuildLearnCard: React.FC = props =>{

  const { t } = useTranslation();
  
  const cards = [{
    title: t( `communityPage.vigorMain` ),
    channel: t( `communityPage.telegram` ),
    logo: VigorMark,
    button: {
      as:"a",
      margin: "0 30%",
      fullWidth: false,
      text: t( `communityPage.join` ),
      href: 'https://t.me/vigorprotocol'
    }
  },{
    title: t( `communityPage.governance` ),
    channel: t( `communityPage.telegram` ),
    logo: VigorMark,
    button: {
      as:"a",
      margin: "0 30%",
      fullWidth: false,
      text: t( `communityPage.join` ),
      href: 'https://t.me/vigordacgovernance'
    }
  },{
    title: t( `communityPage.marketing` ),
    channel: t( `communityPage.telegram` ),
    logo: VigorMark,
    button: {
      as:"a",
      margin: "0 30%",
      fullWidth: false,
      text: t( `communityPage.join` ),
      href: 'https://t.me/vigormarketingandintegrations'
    }
  }, {
    title: t(  `communityPage.developers`),
    channel: t( `communityPage.telegram` ),
    logo: VigorMark,
    button: {
      as:"a",
      margin: "0 30%",
      fullWidth: false,
      text: t( `communityPage.join` ),
      href: 'https://t.me/vigordev'
    }
  }];
  
  const config = { cards };

  return (
    <RowCard {...props} {...config } />
  );
};

export default EarnBuildLearnCard;
