import React from "react";
import Grid from "styled-components-grid";
import WelcomeToVigor from "../components/community/WelcomeToVigor";
import { GridCentered } from "../components/shared";
import GetInvolved from "../components/GetInvolved";
import HowToCandidate from "../components/community/HowToCandidate";
import CustodianBoard from "../components/community/CustodianBoard";
import Jumbotron2 from "../components/Jumbotron2";
import EarnBuildLearnCard from "../components/community/EarnBuildLearnCard";
import { useTranslation } from 'react-i18next';
import Helmet from "react-helmet";

const Community: React.FC<{}> = props => {

  const { t } = useTranslation();
  
  return (
    <React.Fragment>
      <Helmet>
        <meta name="title" content="Vigor DAC - Community Built Defi on EOS"/>
        <meta name="description" content="Join, build, earn VIG Tokens.  The Vigor DAC is building the Vigor Protocol. Crypto lending/borrowing on EOS."/>
      </Helmet>

      <GridCentered>
        <Grid.Unit size={{sm: 10 / 12}}>
          <WelcomeToVigor />
        </Grid.Unit>
      </GridCentered>

      <GridCentered>
        <Grid.Unit size={10/12}>
          <Jumbotron2
            title={ t(`joinBuildEarn-title`) }
            subtitle= { t(`communityPage.howToContribute`)}
            content={ t(`communityPage.vigorDAC`) }
            link={ <p>{ t( `communityPage.telegramLinkText` )}</p>  }
          />
          <EarnBuildLearnCard />
        </Grid.Unit>
      </GridCentered>

      <GridCentered>
        <Grid.Unit size={{sm: 10 / 12}}>
          <CustodianBoard />
        </Grid.Unit>
      </GridCentered>

      <GridCentered>
        <Grid.Unit size={{sm: 10 / 12}}>
          <HowToCandidate />
        </Grid.Unit>
      </GridCentered>

      <GridCentered>
        <Grid.Unit size={{sm: 10 / 12}}>
          <GetInvolved />
        </Grid.Unit>
      </GridCentered>
    </React.Fragment>
  );
};

export default Community;
