import React, { useState } from "react";
import useInterval from "@use-it/interval";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Hero from "../Hero";
import { media } from "../../utils/breakpoints";
import { shuffle } from "../../utils/logic";

const WelcomeWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  & > *:first-child {
    flex: calc(50% - 16px) 0;
  }

  ${media.lessThan(`sm-max`)} {
    flex-direction: column;

    & > *:first-child {
      flex: initial;
    }
  }
`;

const DotsWrapper = styled.div`
  padding: 16px;

  ${media.lessThan(`sm-max`)} {
    margin: 48px 0 0 0;
  }
`;

const DotsGrid = styled.div`
  margin: 0 0 0 auto;
  display: grid;
  grid-template-columns: repeat(10, 16px);
  grid-template-rows: repeat(11, 16px);
  grid-gap: 16px 30px;
  
  ${media.lessThan('sm')} {
    grid-gap: 10px 14px;
  }
  
  justify-items: start;
  align-items: start;
`;

const ANIMATION_TIME_SECONDS = 1
const Dot = styled.div<{
  active?: boolean;
  fadeIn?: boolean;
  fadeOut?: boolean;
}>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${p =>
    p.active ? p.theme.colors.secondary : p.theme.colors.bgLightest};
  animation: ${p =>
    p.fadeIn ? `dot-fadeIn ${ANIMATION_TIME_SECONDS}s ease-in-out` : p.fadeOut ? `dot-fadeIn ${ANIMATION_TIME_SECONDS}s reverse ease-in-out` : `none`};

  @keyframes dot-fadeIn {
    0% {
      background-color: ${p => p.theme.colors.bgLightest};
    }
    100% {
      background-color: ${p => p.theme.colors.secondary};
    }
  }
`;

const DotsLegend = styled.div`
  margin: 32px 0 0 0;
`;

const DotsLegendRow = styled.div`
  display: flex;
  align-items: center;
  height: 19px;
  margin: 0 0 10px 0;

  & > span {
    font-size: 16px;
    margin: 0 0 0 13px;
  }
`;

type Dot = {
  active: boolean;
  fadeOut: boolean;
  fadeIn: boolean;
  index: number;
}
const getInitialDots = ():Dot[] => {
  const dots = Array.from({ length: 10 * 11 }, (_, index) => {
    // we want 21 active dots
    const active = index < 21;
    const fadeOut = index === 20
    const fadeIn = index === 21
    return {
      active,
      fadeOut,
      fadeIn,
      index: NaN,
    };
  });

  // we want the order of active dots to be random
  return shuffle(dots).map((dot, index) => ({ ...dot, index }));
};

const Dots: React.FC<{}> = () => {
  const { t } = useTranslation();

  const [dots, setDots] = useState<Dot[]>(getInitialDots());

  useInterval(() => {
    setDots(dots => {
      const activeNonAnimatingIndexes = dots.filter(d => d.active && !d.fadeIn && !d.fadeOut).map((dot) => dot.index)
      const inactiveNonAnimatingIndexes = dots.filter(d => !d.active && !d.fadeIn && !d.fadeOut).map((dot) => dot.index)
      const [newFadeOutDotIndex] = shuffle(activeNonAnimatingIndexes)
      const [newFadeInDotIndex] = shuffle(inactiveNonAnimatingIndexes)

      // mark old fadeOut as inactive and trigger new fadeOut
      Object.assign(dots.find(d => d.fadeOut), { fadeOut: false, active: false })
      dots[newFadeOutDotIndex].fadeOut = true
      // mark old fadeIn as inactive and trigger new fadeIn
      Object.assign(dots.find(d => d.fadeIn), { fadeIn: false, active: true })
      dots[newFadeInDotIndex].fadeIn = true
      return dots.slice();
    });
  }, ANIMATION_TIME_SECONDS * 1000);

  return (
    <DotsWrapper>
      <DotsGrid>
        {dots.map((dot, index) => (
          <Dot {...dot} key={index} />
        ))}
      </DotsGrid>
      <DotsLegend>
        <DotsLegendRow>
          <Dot active />
          <span>{t(`welcomeToVigor.custodians`)}</span>
        </DotsLegendRow>
        <DotsLegendRow>
          <Dot />
          <span>{t(`welcomeToVigor.candidates`)}</span>
        </DotsLegendRow>
      </DotsLegend>
    </DotsWrapper>
  );
};

type Props = {};
const WelcomeToVigor: React.FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <WelcomeWrapper>
      <Hero
        title={t(`welcomeToVigor.title`)}
        content={t(`welcomeToVigor.description`)}
        link={<a href={"https://dac.vigor.ai"}>{t(`welcomeToVigor.link`)}</a>}
      />
      <Dots />
    </WelcomeWrapper>
  );
};

export default WelcomeToVigor;
