import React from 'react';
import styled from 'styled-components';
import {media} from "../utils/breakpoints";

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

const HeroTitle = styled.h1<any>`
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.colors.primary};
  font-weight: 700;
  margin: 0 0 16px 0;
  
  font-size: 56px;
  ${media.lessThan('sm')} {
    font-size: 42px;
  }
  
  ${props => props.small && `
    font-size: 40px;
    ${media.lessThan('sm')} {
      font-size: 36px;
    }
  `}
`;

const HeroTitleSeparator = styled.div`
  width: 81px;
  height: 2px;
  background-color: ${props => props.theme.colors.secondary};
`;

const HeroSubtitle = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin: 16px 0 71px 0;
  text-align: center;
  
  ${media.lessThan('sm')} {
    font-size: 28px;
  }
`;

const HeroContent = styled.h2`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.34px;
  line-height: 24px;
  margin: 16px 0 48px 0;
`;

// @todo: this should come from a global base button style
const HeroLinkWrap = styled.div`
  display: flex;
  
  a {
    padding: 11px 50px;
    border-radius: 4px;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    text-decoration: none;
    font-size: 14px;
  }
`;

type Props = {
  title: string,
  subtitle?: React.ReactNode,
  content?: React.ReactNode,
  link?: React.ReactNode,
  small?: boolean,
};

const Hero: React.FC<Props> = props => {
  const { title, subtitle, content, link, small } = props;

  return (
    <HeroWrapper>
      <HeroTitle {...props} >{ title }</HeroTitle>
      <HeroTitleSeparator/>
      {subtitle && <HeroSubtitle>{ subtitle }</HeroSubtitle>}
      {content && <HeroContent>{ content }</HeroContent>}
      {link && <HeroLinkWrap>{ link }</HeroLinkWrap>}
    </HeroWrapper>
  );
};

export default Hero;
