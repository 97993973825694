import React from "react";
import styled from "styled-components";
import { BlueButton } from "./shared";
import { useTranslation } from "react-i18next";
import {media} from "../utils/breakpoints";

const Wrapper = styled.div`
  margin: 152px 0 139px 0;
`;

const Title = styled.h1`
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  font-size: 56px;
  font-weight: 700;
  margin: 16px 0;
  text-align: center;
  margin: 0 0 49px 0;
  
  ${media.lessThan('sm')} {
    font-size: 42px;
  }
`;

const LinkWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

type Props = {};

const GetInvolved: React.FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t(`getInvolvedSection.title`)}</Title>
      <LinkWrap>
        <BlueButton as="localizedLink" to={"/faq"}>
          {t(`getInvolvedSection.link`)}
        </BlueButton>
      </LinkWrap>
    </Wrapper>
  );
};

export default GetInvolved;
