import React from 'react';
import styled from 'styled-components';
import {media} from "../utils/breakpoints";

const JumbotronWrapper = styled.div`
  margin-top:152px;
`;

const JumbotronTitle = styled.h1`
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  font-size: 56px;
  font-weight: 700;
  margin: 16px 0;
  text-align: center;
  
  ${media.lessThan('sm')} {
    font-size: 42px;
  }
`;

const JumbotronSubtitle = styled.h2`
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin: 16px 0;
  text-align: center;
  letter-spacing: 0.45px;
`;

const JumbotronTitleSeparator = styled.div`
  width: 81px;
  height: 2px;
  margin: 0 auto;
  background-color: ${props => props.theme.colors.secondary};
`;

const JumbotronContent = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 16px auto 51px;
  max-width: 662px;
  text-align: center;
  color: ${props => props.theme.colors.primary};
`;

// @todo: this should come from a global base button style
const JumbotronLinkWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  a {
    padding: 11px 50px;
    border-radius: 4px;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    text-decoration: none;
    font-size: 14px;
  }
  p{
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    color: ${props => props.theme.colors.white};
  }
`;

type Props = {
  title:    string,
  subtitle?:     string,
  content:  React.ReactNode,
  link?:     React.ReactNode,
};

const Jumbotron2: React.FC<Props> = props => {
  const { title, content, link, subtitle } = props;

  return (
    <JumbotronWrapper>
      <JumbotronTitle>{ title }</JumbotronTitle>
      <JumbotronTitleSeparator/>
      
      { subtitle &&
        <JumbotronSubtitle>
          { subtitle }
        </JumbotronSubtitle>
      }

      <JumbotronContent>{ content }</JumbotronContent>
      { link && <JumbotronLinkWrap>{ link }</JumbotronLinkWrap>}
    </JumbotronWrapper>
  );
};

export default Jumbotron2;
