import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Hero from "../Hero";
import { media } from "../../utils/breakpoints";

const HowToCandidateWrapper = styled.div`

`;

const Step = styled.div`
  display: flex;
  margin-top: 36px;
`;

const StepNumber = styled.div`
  flex-shrink: 0;
  height: 68px;
  width: 68px; 
  border-radius: 100%;
  border: 2px solid ${props => props.theme.colors.primaryLighter};
  font-size: 24px;
  font-weight: 700;
  color: ${props => props.theme.colors.primaryLighter};
  text-align: center;
  line-height: 63px;
  margin-right: 40px;
  
  ${media.lessThan('xs')} {
    height: 48px;
    width: 48px; 
    font-size: 22px;
    line-height: 44px;
    margin-right: 24px;
  }
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StepText = styled.div`
  color: ${props => props.theme.colors.whiteDarkened};
  line-height: 24px;
  font-size: 16px;
`;

const StepTextNote = styled.div`
  font-size: 14px;
  font-style: italic;
  color: ${props => props.theme.colors.secondary};
`;

const LinkWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  
  ${media.lessThan('xs')} {
    display: block;
  }
`;

const StyledLinkButton = styled.a`
  border: 1px solid ${props => props.theme.colors.primaryLighter};
  color: ${props => props.theme.colors.primaryLighter};
  border-radius: 4px;
  padding: 7px 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  min-width: 120px;
  
  ${media.lessThan('xs')} {
    display: block;
    
    :not(:first-child) {
      margin-top: 8px;
    }
  }
`;

const StyledLink = styled.a`
  color: ${props => props.theme.colors.primaryLighter};
`;

const StepsToCandidacy: React.FC<{}> = () => {
  const {t} = useTranslation();

  return (
    <div>
      <Step>
        <StepNumber>1</StepNumber>
        <StepContent>
          <StepText>{ t('howToCandidate.step.1.text') }</StepText>
          <LinkWrap>
            <StyledLinkButton href='https://vig.ai' target='_blank' rel='noopener'>{ t('howToCandidate.step.1.link_vig') }</StyledLinkButton>
            <StyledLinkButton href='https://dac.vigor.ai' target='_blank' rel='noopener'>{ t('howToCandidate.step.1.link_vigor') }</StyledLinkButton>
          </LinkWrap>
        </StepContent>
      </Step>
      <Step>
        <StepNumber>2</StepNumber>
        <StepContent>
          <StepText>{ t('howToCandidate.step.2.text') }</StepText>
        </StepContent>
      </Step>
      <Step>
        <StepNumber>3</StepNumber>
        <StepContent>
          <StepText>
            { t('howToCandidate.step.3.text_1') }
          </StepText>
          <LinkWrap>
            <StyledLinkButton href='https://vig.ai' target='_blank' rel='noopener'>{ t('howToCandidate.step.3.link') }</StyledLinkButton>
          </LinkWrap>
        </StepContent>
      </Step>
      <Step>
        <StepNumber>4</StepNumber>
        <StepContent>
          <StepText>
            { t('howToCandidate.step.4.text_start') }<StyledLink href='https://vig.ai' target='_blank' rel='noopener'>{ t('howToCandidate.step.4.text_link') }</StyledLink>{ t('howToCandidate.step.4.text_end') }
          </StepText>
        </StepContent>
      </Step>
      <Step>
        <StepNumber>5</StepNumber>
        <StepContent>
          <StepText>{ t('howToCandidate.step.5.text') }</StepText>
        </StepContent>
      </Step>
    </div>
  );
};

type Props = {};
const HowToCandidate: React.FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <HowToCandidateWrapper>
      <Hero
        title={t(`howToCandidate.title`)}
        content={<StepsToCandidacy/>}
        small={true}
      />
    </HowToCandidateWrapper>
  );
};

export default HowToCandidate;
